import Breadcrumb from "../component/Breadcrumb";

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
const importAll = (r) => r.keys().map(r);

export default function Gallery() {
  const images = importAll(require.context('../gallery/', false, /\.(png|jpe?g|svg)$/));
  // console.log(allimages);
  
  // const images = [
  //   "../gallery/01.jpg",
  //   "../gallery/02.jpg",
  //   "../gallery/03.jpg",
  //   "../gallery/04.jpg",
  //   "../gallery/03.jpg",
  //   "../gallery/02.jpg",
  // ];

  return (
    <>
      <Breadcrumb title={"Gallery"} />

      <div className="rts-team-area rts-section-gap bg-team-color" >
        <div className="container">
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
      <Masonry gutter="10px">
        {images.map((image, index) => (
          <img key={index} src={image} alt={`img-${index}`} style={{ width: "100%", display: "block" }} />
        ))}
      </Masonry>
    </ResponsiveMasonry>
        </div>
      </div>
    </>
  );
}
