import AboutSection from "../section/AboutSection";
import ContactSection from "../section/ContactSection";
import HomeSlider from "../section/HomeSlider";
import NewsSection from "../section/NewsSection";
import ServicesSection from "../section/ServicesSection";
import TeamSection from "../section/TeamSection";

export default function Home() {
    return (<>
    <HomeSlider/>
    <AboutSection/>
    <ServicesSection/>
    <TeamSection />
    <NewsSection/>
    <ContactSection/>
    </>)
};