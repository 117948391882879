import React from 'react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Shape01 from '../assets/images/banner/shape/01.png';
import Shape02 from '../assets/images/banner/shape/02.png';
import Shape03 from '../assets/images/banner/shape/03.png';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';



export default function HomeSlider() {
  return (
    <>
      {/* banner blank space area */}
      <div className="rts-banner-area rts-banner-one">
        <Swiper
         modules={[Navigation, Pagination, Autoplay]}
          loop={false}
          autoHeight={true}
          effect="fade"
          speed={500}
          autoplay={{
            delay: 4000,
          }}
          className="swiper mySwiper banner-one"
        >
          <SwiperSlide className="swiper-slide">
          {/* banner single content */}
          <div className="banner-one-inner text-start">
            <p className="pre-title">
              <span> Al-Khair Baitul Maal</span> Urban Co-operative Credit Society Limited
            </p>
            <h1 className="title ">
              Promoting <span>Interest-Free</span> Financial Services
            </h1>
            <p className="disc banner-para">
            Facilitate the development of interest-free micro-finance <br/> institutions (IFMFI) to offer fair financial solutions for all.
            </p>
            <a href="/About" className="rts-btn btn-primary color-h-black">
              Learn More
            </a>
            <img
              className="shape-img one"
              src={Shape01}
              alt="banner_business"
            />
          </div>
          {/* banner single content end */}
        </SwiperSlide>
        <SwiperSlide className="swiper-slide two">
          {/* banner single content */}
          <div className="banner-one-inner text-start">
            <p className="pre-title">
              <span> Al-Khair Baitul Maal</span> Urban Co-operative Credit Society Limited
            </p>
            <h1 className="title ">
            Supporting <span> Financial Inclusion</span>
            </h1>
            <p className="disc banner-para">
            Ensure that individuals from all walks of life, especially those who are marginalized,<br/> have access to financial services that meet their needs.
            </p>
            <a href="/About" className="rts-btn btn-primary color-h-black">
              Learn More
            </a>
            <img
              className="shape-img one"
              src={Shape01}
              alt="banner_business"
            />
          </div>
          {/* banner single content end */}
        </SwiperSlide>
        <SwiperSlide className="swiper-slide three">
          {/* banner single content */}
          <div className="banner-one-inner text-start">
            <p className="pre-title">
              <span> Al-Khair Baitul Maal</span> Urban Co-operative Credit Society Limited
            </p>
            <h1 className="title ">
            Encouraging <span>Savings</span> <br/> and <span>Investment</span>
            </h1>
            <p className="disc banner-para">
            Motivate members to adopt saving habits and reinvest in the community<br/> for mutual benefit and prosperity.
            </p>
            <a href="/About" className="rts-btn btn-primary color-h-black">
              Learn More
            </a>
            <img
              className="shape-img one"
              src={Shape01}
              alt="banner_business"
            />
          </div>
          {/* banner single content end */}
        </SwiperSlide>
        </Swiper>
        <div className="animation-img">
          <img className="shape-img two" src={Shape02} alt="banner_business" />
          <img className="shape-img three" src={Shape03} alt="banner_business" />
        </div>
      </div>
      {/* banner blank space area end */}
    </>
  );
}
