import React, { useState } from 'react';
import logo from '../assets/images/logo/logo-1.svg';
import menu from"../assets/images/icon/menu.png"
import menuLight from"../assets/images/icon/menu-light.png"
import { Link } from 'react-router-dom'

export default function Header() {
    const [isSidebarVisible, setSidebarVisible] = useState(false);

    const toggleSidebar = () => {
      setSidebarVisible(!isSidebarVisible);
    };
  
    const closeSidebar = () => {
      setSidebarVisible(false);
    };

    const [activeMenu, setActiveMenu] = useState(null);

    const handleToggle = (index) => {
      if (activeMenu === index) {
        setActiveMenu(null); // Close if the same menu is clicked
      } else {
        setActiveMenu(index); // Open the clicked menu
      }
    };

    const menuItems = [
        {
          title: "Home",
          link: "/",
        },
        {
          title: "About Us",
          link: "about",
          submenu: [
            { title: "About us", link: "about" },
            { title: "ByLaws", link: "bylaws" },
            { title: "Board of Directors", link: "board" },
            { title: "Resolutions of Board", link: "resolutions" },
          ],
        },
        // {
        //   title: "Activities",
        //   link: "",
        //   submenu: [
        //     { title: "Micro Finance", link: "activities/microfinance" },
        //     { title: "Co-operative", link: "activities/cooperative" },
        //     { title: "Membership", link: "activities/membership" },
        //     { title: "Accounts", link: "activities/accounts" },
        //     { title: "Loans", link: "activities/loans" },
        //   ],
        // },
        // {
        //   title: "Reports",
        //   link: "",
        //   submenu: [
        //     { title: "Annual Report", link: "reports/annual" },
        //     { title: "Audit Report", link: "reports/audit" },
        //     { title: "Loan Report", link: "reports/loan" },
        //   ],
        // },
        // {
        //   title: "Career",
        //   link: "",
        //   submenu: [
        //     { title: "Current Opening", link: "career/current" },
        //     { title: "Process", link: "career/process" },
        //   ],
        // },
        {
          title: "Gallery",
          link: "gallery",
        },
        {
          title: "Contact",
          link: "contact",
        },
      ];
  return (
    <>
      {/* start header area */}
      {/* start header area */}
      <header className="header--sticky header-one ">
        <div className="header-top header-top-one bg-1">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-xl-block d-none">
                <div className="left">
                  <div className="mail">
                    <a href="mailto:alkhair.baitulmall@gmail.com">
                      <i className="fal fa-envelope" /> alkhair.baitulmall@gmail.com
                    </a>
                  </div>
                  <div className="working-time">
                    <p>
                      <i className="fal fa-clock" /> Working: 10:00 AM to 5:30 PM
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-xl-block d-none">
                <div className="right">
                  <ul className="top-nav">
                    <li>
                      <a href="../">About</a>
                    </li>
                    <li>
                      <Link to="../News">News</Link>
                    </li>
                    <li>
                      <a href="../">Contact</a>
                    </li>
                  </ul>
                  <ul className="social-wrapper-one">
                    <li>
                      <a href="../">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="../">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="../">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a className="mr--0" href="../">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-main-one bg-white">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4">
                <div className="thumbnail">
                  <a href="../" style={{position: "absolute"}}>
                    <img
                      src={logo}
                      alt="alkhair-logo"
                      style={{height: "80px", position: "relative", top: "-20px"}}
                    />
                  </a>
                </div>
              </div>
              <div className=" col-xl-9 col-lg-8 col-md-8 col-sm-8 col-8">
                <div className="main-header">
                  <nav className="nav-main mainmenu-nav d-none d-xl-block">
                    <ul className="mainmenu">
                    {menuItems.map((item, index) => (
          <li key={index} className={`menu-item ${item.submenu ? "has-droupdown" : ""}`}>
            <Link
              className="menu-link"
              to={item.link}
            >
              {item.title}
            </Link>
            {item.submenu && (
              <ul className={`submenu ${activeMenu === index ? "active" : ""}`}>
                {item.submenu.map((subitem, subIndex) => (
                  <li key={subIndex} >
                    <Link to={subitem.link}>{subitem.title}</Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
                      {/* <li>
                          <a className="nav-link" href="../">
                            Home
                          </a>
                      </li>
                      <li className="has-droupdown">
                        <a className="nav-link" href="../">
                          About us
                        </a>
                        <ul className="submenu">
                          <li>
                            <a href="../">Aims & Objective</a>
                          </li>
                          <li>
                            <a href="../">ByLaws</a>
                          </li>
                          <li>
                            <a href="../">Board of Directors</a>
                          </li>
                          <li>
                            <a href="../">Resolutions of Board</a>
                          </li>
                        </ul>
                      </li>
                      <li className="has-droupdown">
                        <a className="nav-link" href="../">
                            Activities
                        </a>
                        <ul className="submenu">
                          <li>
                            <a href="../">Micro Finance</a>
                          </li>
                          <li>
                            <a href="../">Co-operative</a>
                          </li>
                          <li>
                            <a href="../">Membership</a>
                          </li>
                          <li>
                            <a href="../">Accounts</a>
                          </li>
                          <li>
                            <a href="../">Loans</a>
                          </li>
                        </ul>
                      </li>
                      <li className="has-droupdown">
                        <a className="nav-link" href="../">
                            Reports
                        </a>
                        <ul className="submenu">
                          <li>
                            <a href="../">Annual Report</a>
                          </li>
                          <li>
                            <a href="../">Audit Report</a>
                          </li>
                          <li>
                            <a href="../">Loan Report</a>
                          </li>
                        </ul>
                      </li>
                      <li className="has-droupdown">
                        <a className="nav-link" href="../">
                            Career
                        </a>
                        <ul className="submenu">
                          <li>
                            <a href="../">Current Opening</a>
                          </li>
                          <li>
                            <a href="../">Process</a>
                          </li>
                        </ul>
                      </li>
                      
                      <li>
                        <a className="nav-item" href="contactus.html">
                          Gallery
                        </a>
                      </li>
                      <li>
                        <a className="nav-item" href="contactus.html">
                          Contact
                        </a>
                      </li> */}
                    </ul>
                  </nav>
                  <div className="button-area">
                    {/* <button id="search" className="rts-btn btn-primary-alta">
                      <i className="far fa-search" />
                    </button> */}
                  
                    <button
                      id="menu-btn" 
                      onClick={toggleSidebar}
                      className="menu rts-btn btn-primary-alta ml--20 ml_sm--5"
                    >
                      <img
                        className="menu-dark"
                        src={menu}
                        alt="Menu-icon"
                      />
                      <img
                        className="menu-light"
                        src={menuLight}
                        alt="Menu-icon"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* End header area */}
      <div id="side-bar" className={`side-bar ${isSidebarVisible ? 'show' : ''}`}>
        <button className="close-icon-menu" onClick={closeSidebar}>
          <i className="far fa-times" />
        </button>
        {/* inner menu area desktop start */}
        <div className="rts-sidebar-menu-desktop">
          <Link className="logo-1" to="../">
            <img
              className="logo"
              src={logo}
              alt="alkhair_logo"
            />
          </Link>
          <Link className="logo-2" to="../">
            <img
              className="logo"
              src={logo}
              alt="alkhair_logo"
            />
          </Link>
       
          <a className="logo-4" href="../">
            <img
              className="logo"
              src="assets/assets/images/logo/logo-5.svg"
              alt="alkhair_logo"
            />
          </a>
          <div className="body d-none d-xl-block">
            <p className="disc">
            Al-Khair Baitul Maal Urban Co-operative Credit Society Limited, established in 2002, is a voluntary, non-political, and non-profit social service organization based in Aurangabad, Maharashtra. We are registered under the Maharashtra State Co-operative Society Act of 1960 and are dedicated to promoting interest-free financial services in our community.
            </p>
            <div className="get-in-touch">
              {/* title */}
              <div className="h6 title">Get In Touch</div>
              {/* title End */}
              <div className="wrapper">
                {/* single */}
                <div className="single">
                  <i className="fas fa-phone-alt" />
                  <a href="tel:+8801234566789">+8801234566789</a>
                </div>
                {/* single ENd */}
                {/* single */}
                <div className="single">
                  <i className="fas fa-envelope" />
                  <a href="mailto:alkhair.baitulmall@gmail.com">alkhair.baitulmall@gmail.com</a>
                </div>
                {/* single ENd */}
                {/* single ENd */}
                {/* single */}
                <div className="single">
                  <i className="fas fa-map-marker-alt" />
                  Markaz-e-Islami Building Yunus Colony Kat Kat Gate Road, Aurangabad. 431001
                </div>
                {/* single ENd */}
              </div>
              <div className="social-wrapper-two menu">
                <a href="../">
                  <i className="fab fa-facebook-f" />
                </a>
                <a href="../">
                  <i className="fab fa-twitter" />
                </a>
                <a href="../">
                  <i className="fab fa-instagram" />
                </a>
                <a href="../">
                  <i className="fab fa-whatsapp" />
                </a>
                {/* <a href="../"><i class="fab fa-linkedin"></i></a> */}
              </div>
            </div>
          </div>
          <div className="body-mobile d-block d-xl-none">
            <nav className="nav-main mainmenu-nav">
                <ul className="mainmenu">
                  {menuItems.map((item, index) => (
                    <li key={index} className={`menu-item ${item.submenu ? "has-droupdown" : ""}`}>
                      <a className="menu-link" href={item.link} onClick={(e) => {if (item.submenu) {  e.preventDefault();  handleToggle(index);}}}>
                        {item.title}
                      </a>
                      {item.submenu && (
                        <ul className={`submenu ${activeMenu === index ? "active" : ""}`}>
                          {item.submenu.map((subitem, subIndex) => (
                            <li key={subIndex} className="mobile-menu-link">
                              <a href={subitem.link}>{subitem.title}</a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
            </nav>
            <div className="social-wrapper-two menu mobile-menu">
              <a href="../">
                <i className="fab fa-facebook-f" />
              </a>
              <a href="../">
                <i className="fab fa-twitter" />
              </a>
              <a href="../">
                <i className="fab fa-instagram" />
              </a>
              <a href="../">
                <i className="fab fa-whatsapp" />
              </a>
              {/* <a href="../"><i class="fab fa-linkedin"></i></a> */}
            </div>
            <a
              href="../"
              className="rts-btn btn-primary ml--20 ml_sm--5 header-one-btn quote-btnmenu"
            >
              Get Quote
            </a>
          </div>
        </div>
        {/* inner menu area desktop End */}
      </div>
      <div className="search-input-area">
        <div className="container">
          <div className="search-input-inner">
            <div className="input-div">
              <input
                id="searchInput1"
                className="search-input"
                type="text"
                placeholder="Search by keyword or #"
              />
              <button>
                <i className="far fa-search" />
              </button>
            </div>
          </div>
        </div>
        <div id="close" className="search-close-icon">
          <i className="far fa-times" />
        </div>
      </div>
      <div className="loader-wrapper">
      <div className="loader">
      </div>
      <div className="loader-section section-left"></div>
      <div className="loader-section section-right"></div>
  </div>
      <div id="anywhere-home" className={`anywhere-home ${isSidebarVisible ? 'bgshow' : ''}`} onClick={closeSidebar}></div>
      {/* ENd Header Area */}
    </>
  );
};
