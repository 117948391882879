import Breadcrumb from "../component/Breadcrumb";

import NewsSection from "../section/NewsSection";

export default function Board() {
    return(<>
    <Breadcrumb title="News"/>
    <NewsSection/>

    </>)
}