import logo from '../assets/images/logo/logo.png';
import underfooter from '../assets/images/footer/under-title.png';

export default function footer() {
    return (<>
    {/* rts footer area start */}
  <div className="rts-footer-area footer-one  bg-footer-one">
    <div className="container bg-shape-f1">

      {/* rts footer area */}
      <div className="row pt--120 pt_sm--80 pb--80 pb_sm--40">
        <div className="col-xl-6 col-md-6 col-sm-12 col-12">
          <div className="footer-one-single-wized">
            <div className="wized-title">
              <img
                src={logo}
                // style={{height: "80px", position: "relative", top: "-20px"}}
                alt="finbiz_footer"
              />
              <p className="title">Al-Khair Baitul Maal Urban Co-operative Credit Society Limited</p>
            </div>
            <div className="quick-link-inner">
              
            Al-Khair Baitul Maal Urban Co-operative Credit Society Limited, established in 2002, is a voluntary, non-political, and non-profit social service organization based in Aurangabad, Maharashtr
            </div>
          </div>
        </div>
        {/* footer mid area */}
        <div className="col-xl-6 col-md-6 col-sm-12 col-12">
          <div className="footer-one-single-wized mid-bg">
            <div className="wized-title">
              <h5 className="title">Opening Hours</h5>
              <img
                src={underfooter}
                alt="finbiz_footer"
              />
            </div>
            <div className="opening-time-inner">
              <div className="single-opening">
                <p className="day">Timing:</p>
                <p className="time">10:00 am to 5:30 pm</p>
              </div>
              {/* <div className="single-opening">
                <p className="day">Cash Timing:</p>
                <p className="time">10 am to 5:30 pm</p>
              </div> */}
              <div className="single-opening mb--30 mb_sm--10">
                <p className="day">Sunday</p>
                <p className="time">Day Off</p>
              </div>
              <a href="#" className="rts-btn btn-primary contact-us">
                Contact Us
              </a>
            </div>
          </div>
        </div>
        {/* footer mid area end */}
       
      </div>
      {/* rts footer area End */}
    </div>
    {/* copyright area start */}
    <div className="rts-copyright-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <p>Website by <a href="https://graphicon.in">Graphicon Design Studio</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* copyright area end */}
  </div>
  {/* rts footer area end */}
{/* progress Back to top */}
  <div className="progress-wrap">
    <svg
      className="progress-circle svg-content"
      width="100%"
      height="100%"
      viewBox="-1 -1 102 102"
    >
      <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
  </div>
  {/* progress Back to top End */}
    </>)
}