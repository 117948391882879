import Breadcrumb from "../component/Breadcrumb";
import call from "../assets/images/about/call.svg"
import about01 from "../assets/images/about/main/about-01.png"
import about02 from "../assets/images/about/main/about-02.png"
import ServicesSection from "../section/ServicesSection";

export default function About() {
    return (<>
    <Breadcrumb title="About us"/>
    <div className="rts-about-area rts-section-gap bg-about-sm-shape" >
    <div className="container">
      <div className="row g-5 align-items-center">
        {/* about left */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 mt_md--50 mt_sm--50">
          <div className="rts-title-area">
            <p className="pre-title">Al-Khair Baitul Maal Urban Co-operative Credit Society Limited</p>
            <h2 className="title">About us</h2>
          </div>
          <div className="about-inner">
            <p className="disc">
            Al-Khair Baitul Maal Urban Co-operative Credit Society Limited, established in 2002, is a voluntary, non-political, and non-profit social service organization based in Aurangabad, Maharashtra. We are registered under the Maharashtra State Co-operative Society Act of 1960 and are dedicated to promoting interest-free financial services in our community.

Our primary aim is to facilitate and develop interest-free micro-finance institutions (IFMFI) that empower individuals through ethical and inclusive financial solutions. By offering need-based research and services, we strive to help those who lack access to conventional banking systems, ensuring they can take control of their financial futures without the burden of interest.

Guided by values of fairness, equality, and social justice, we are a member-driven organization that operates on the principles of democracy. Every member has an equal say, ensuring transparency and fairness in all our operations. Through our co-operative structure, we reinvest profits into the community, fostering sustainable development and long-term prosperity.

            </p>

           
          
          </div>
        </div>
        {/* about right */}
        {/* about-right Start*/}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
          <div className="about-one-thumbnail">
            <img
              src={about01}
              alt="about-finbiz"
            />
            <img
              className="small-img"
              src={about02}
              alt="finbiz-small"
            />
            <div className="experience">
              <div className="left single">
                <h2 className="title">20+</h2>
                <p className="time">Years</p>
              </div>
              <div className="right single">
                <p className="disc">Of experience </p>
              </div>
            </div>
          </div>
        </div>
        {/* about-right end */}
      </div>
    <div style={{paddingTop: "2.5rem"}}>
    <p >

With a dedicated board of directors, and a mission rooted in ethical finance, Al-Khair Baitul Maal is more than just a financial institution—it's a movement towards a just and equitable society. We invite you to join us in our mission to create a world where financial services benefit all, without the burden of interest.

For more information, visit our office at Markaz-e-Islami Building, Yunus Colony, Kat Kat Gate Road, Aurangabad.


Every member has an equal say, ensuring transparency and fairness in all our operations. Through our co-operative structure, we reinvest profits into the community, fostering sustainable development and long-term prosperity. With a dedicated board of directors, and a mission rooted in ethical finance, Al-Khair Baitul Maal is more than just a financial institution—it's a movement towards a just and equitable society. We invite you to join us in our mission to create a world where financial services benefit all, without the burden of interest. For more information, visit our office at Markaz-e-Islami Building, Yunus Colony, Kat Kat Gate Road, Aurangabad.</p>
     
</div>
      {/* </div>
  </div>
  
  <div className="container " style={{marginTop:'0px',marginBottom:'40px'}}> */}
      <div className="row gx-5">
        <div className="col-md-6">
          <h2 className="title">Our Mission</h2>
          <p className="disc">
          To provide equitable and interest-free financial solutions that empower individuals and communities. Through ethical, inclusive, and accessible micro-finance services, we strive to uplift those underserved by traditional banking systems and promote sustainable economic development. Our mission is to foster financial independence without the burden of interest, ensuring fairness and justice in all our services.
          </p>
        </div>
        <div className="col-md-6">
          <h2 className="title">Our Vision</h2>
          <p className="disc">
          To be a leading interest-free co-operative society that transforms lives by promoting financial justice, equality, and social well-being. We envision a world where ethical finance is at the heart of community-driven development, creating a society where economic growth is shared and sustainable, benefiting every individual without exploitation.
          </p>
        </div>
      </div>
    </div>
    </div>

    <ServicesSection/>
    </>)
};