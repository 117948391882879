export default function Breadcrumb({title}) {
    return(<>
        {/* start breadcrumb area */}
        <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
                <h1 className="title"> {title}</h1>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="bread-tag">
                  <a href="index.html">Home</a>
                  <span> / </span>
                  <a href="#" className="active">
                    {title}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end breadcrumb area */}

        
      </>
      )
}