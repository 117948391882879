export default function ContactSection() {
    return (<>
        {/* map area start */}
        <div className="rts-map-area bg-light-white" >
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6">
                {/* map area left */}
                <div className="mapdetails-inner-one">
                  <div className="left-area single-wized">
                    <h5 className="title">Get in touch</h5>
                    <div className="details">
                      <p>Contact No.</p>
                      <a className="number">
                        0240-2311073
                      </a>
                     
                      <p className="time-header">Timing:</p>
                      <p className="time">
                      Monday – Saturday <br />10:00 am to 05:30 pm
                      </p>
                      {/* <p className="time-header">Cash Timing:</p>
                      <p className="time">
                        Monday – Saturday <br /> 10:00 am to 05:30 pm
                      </p> */}
                    </div>
                  </div>
                  <div className="right-area single-wized">
                    <h5 className="title">Address</h5>
                    <div className="details">
                      <p>Service Office</p>
                      <a href="#">
                      Markaz-e-Islami Building Yunus Colony Kat Kat Gate Road, Aurangabad. 431001

                      </a>
                    </div>
                  </div>
                </div>
                {/* map area right */}
              </div>
              <div className="col-lg-6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d234.4863751883159!2d75.34377171894056!3d19.89142497293131!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdba2a719461ed3%3A0x8b430dfc2df6d64a!2sMarkaz%20Jamat-e-Islami%20Hind%2C%20Aurangabad.!5e0!3m2!1sen!2sin!4v1728027803609!5m2!1sen!2sin"
                  width={600}
                  height={450}
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </div>
        {/* map area end */}
      </>)
}