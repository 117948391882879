import Breadcrumb from "../component/Breadcrumb";

export default function Bylaws() {
    return(<>
    
    <Breadcrumb title="By Laws"/>
    <div className="rts-typography-style rts-section-gap">
  <div className="container">
    <div className="style">
      <h1 className="title">By Laws</h1>
      <p>
      At Al-Khair Baitul Maal Urban Co-operative Credit Society Limited, we believe in maintaining transparency, fairness, and clarity in all our dealings. The following bylaws have been established to ensure smooth and efficient operations for the benefit of all our members:
      </p>
      {/* <h2>Society Account Requirements</h2> */}

<h3>1. Passbook Requirement</h3>
<p>Members are required to bring their passbook when conducting any transactions with the society.</p>

<h3>2. Minimum Balance</h3>
<p>All accounts must maintain a minimum balance of ₹1,000 at all times.</p>

<h3>3. Monthly Savings Requirement</h3>
<p>A minimum deposit of ₹100 must be made into savings accounts every month.</p>

<h3>4. Withdrawal Notice</h3>
<p>For withdrawals exceeding ₹50,000, members are required to inform the society one day in advance.</p>

<h3>5. Passbook Safety</h3>
<p>It is the responsibility of account holders to keep their passbooks safe. The society is not liable for any negligence or loss due to mishandling by the members.</p>

    </div>
  </div>
</div>

    </>)
}