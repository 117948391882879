import icon01 from "../assets/images/service/icon/01.svg";
import icon02 from "../assets/images/service/icon/02.svg";
import icon03 from "../assets/images/service/icon/03.svg";
import icon04 from "../assets/images/service/icon/04.svg";
import icon05 from "../assets/images/service/icon/05.svg";
// import icon06 from "../assets/images/service/icon/06.svg";
export default function ServicesSection (){
    return(<>
        {/* rts service post area  Start*/}
        <div className="rts-service-area rts-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="rts-title-area service text-center">
                  {/* <p className="pre-title">Our Services</p> */}
                  <h2 className="title">Our Objectives</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid service-main plr--120-service mt--50 plr_md--0 pl_sm--0 pr_sm--0">
          <div className="background-service row">
  {/* start single Service */}
  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
    <div className="service-one-inner one">
      <div className="thumbnail">
        <img src={icon01} alt="finbiz_service" />
      </div>
      <div className="service-details">
        <a href="./">
          <h5 className="title">Promote Interest-Free Finance</h5>
        </a>
        <p className="disc">
          Facilitate the development of interest-free micro-finance institutions (IFMFI) to offer fair financial solutions for all.
        </p>
        <a className="rts-read-more btn-primary" href="./">
          <i className="far fa-arrow-right" />
          Read More
        </a>
      </div>
    </div>
  </div>
  {/* end single Services */}
  {/* start single Service */}
  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
    <div className="service-one-inner two">
      <div className="thumbnail">
        <img src={icon02} alt="finbiz_service" />
      </div>
      <div className="service-details">
        <a href="./">
          <h5 className="title">Support Inclusive Finance for All</h5>
        </a>
        <p className="disc">
          Ensure that individuals from all walks of life, especially those who are marginalized, have access to financial services that meet their needs.
        </p>
        <a className="rts-read-more btn-primary" href="./">
          <i className="far fa-arrow-right" />
          Read More
        </a>
      </div>
    </div>
  </div>
  {/* end single Services */}
  {/* start single Service */}
  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
    <div className="service-one-inner three">
      <div className="thumbnail">
        <img src={icon03} alt="finbiz_service" />
      </div>
      <div className="service-details">
        <a href="./">
          <h5 className="title">Encourage Savings and Investments</h5>
        </a>
        <p className="disc">
          Motivate members to adopt saving habits and reinvest in the community for mutual benefit and prosperity.
        </p>
        <a className="rts-read-more btn-primary" href="./">
          <i className="far fa-arrow-right" />
          Read More
        </a>
      </div>
    </div>
  </div>
  {/* end single Services */}
  {/* start single Service */}
  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
    <div className="service-one-inner four">
      <div className="thumbnail">
        <img src={icon04} alt="finbiz_service" />
      </div>
      <div className="service-details">
        <a href="./">
          <h5 className="title">Conduct Research Based on Needs</h5>
        </a>
        <p className="disc">
          Undertake and promote research that addresses the unique financial challenges faced by underserved populations, contributing to the continuous improvement of our services.
        </p>
        <a className="rts-read-more btn-primary" href="./">
          <i className="far fa-arrow-right" />
          Read More
        </a>
      </div>
    </div>
  </div>
  {/* end single Services */}
  {/* start single Service */}
  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
    <div className="service-one-inner five">
      <div className="thumbnail">
        <img src={icon05} alt="finbiz_service" />
      </div>
      <div className="service-details">
        <a href="./">
          <h5 className="title">Empower the Community Financially</h5>
        </a>
        <p className="disc">
          Strengthen the socio-economic fabric of our community through ethical, non-profit financial services, ensuring long-term development and security for all members.
        </p>
        <a className="rts-read-more btn-primary" href="./">
          <i className="far fa-arrow-right" />
          Read More
        </a>
      </div>
    </div>
  </div>
  {/* end single Services */}
</div>

            <div className="row">
              <div className="cta-one-bg col-12">
                <div className="cta-one-inner">
                  <div className="cta-left">
                    <h3 className="title">
                      Let’s discuss about how we can help you
                    </h3>
                  </div>
                  <div className="cta-right">
                    <a className="rts-btn btn-white" href="./">
                      Contact us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* rts service post area ENd */}
      </>
      )
}