import Breadcrumb from "../component/Breadcrumb";
import img01 from "../assets/images/contact/01.png"
import img02 from "../assets/images/contact/02.png"
import img03 from "../assets/images/contact/03.png"

import shape01 from "../assets/images/contact/shape/01.svg"
import shape02 from "../assets/images/contact/shape/02.svg"
import shape03 from "../assets/images/contact/shape/03.svg"
export default function Contact() {
    return(<>
    <Breadcrumb title={"Contact us"}/>
    <div className="rts-contact-area rts-section-gap">
  <div className="container">
    <div className="row g-5">
      {/* single contact area */}
      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="single-contact-one-inner">
          <div className="thumbnail">
            <img src={img01} alt="" />
          </div>
          <div className="content">
            <div className="icone">
              <img src={shape01} alt="" />
            </div>
            <div className="info">
              <span>Call Timing 10:00 am to 05:30 pm</span>
              <a href="tel:0240-2311073">
                <h5>0240-2311073</h5>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* single contact area end */}
      {/* single contact area */}
      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="single-contact-one-inner">
          <div className="thumbnail">
            <img src={img02} alt="" />
          </div>
          <div className="content">
            <div className="icone">
              <img src={shape02} alt="" />
            </div>
            <div className="info">
              <span>Enquiries</span>
              <a href="mailto:alkhair.baitulmall@gmail.com">
                <h5>alkhair.baitulmall@gmail.com</h5>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* single contact area end */}
      {/* single contact area */}
      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="single-contact-one-inner">
          <div className="thumbnail">
            <img src={img03} alt="" />
          </div>
          <div className="content">
            <div className="icone">
              <img src={shape03} alt="" />
            </div>
            <div className="info">
              <span>Service Station</span>
              <a href="#">
                <h5>Markaz-e-Islami Building Yunus Colony Kat Kat Gate Road, Aurangabad. 431001.</h5>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* single contact area end */}
    </div>
  </div>
</div>

    <div className="rts-contact-form-area">
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="rts-contact-fluid rts-section-gap">
          <div className="rts-title-area contact-fluid text-center mb--50">
            <p className="pre-title">Get In Touch</p>
            <h2 className="title">Needs Help? Let’s Get in Touch</h2>
          </div>
          <div className="form-wrapper">
            <div id="form-messages" />
            <form id="contact-form" method="post">
              <div className="name-email">
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  required=""
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  required=""
                />
              </div>
              <input type="text" name="subject" placeholder="Your Subject" />
              <textarea
                placeholder="Type Your Message"
                name="message"
                defaultValue={""}
              />
              <button type="submit" className="rts-btn btn-primary">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>)
}