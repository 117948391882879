import React from 'react';
import tm01 from '../assets/images/team/tm/01.jpg';
import tm02 from '../assets/images/team/tm/02.jpg';
import tm03 from '../assets/images/team/tm/03.jpg';
import tm04 from '../assets/images/team/tm/04.jpg';
import tm05 from '../assets/images/team/tm/05.jpg';
import tm06 from '../assets/images/team/tm/06.jpg';
import tm07 from '../assets/images/team/tm/07.jpg';
import tm08 from '../assets/images/team/tm/08.jpg';
import tm09 from '../assets/images/team/tm/09.jpg';
import tm10 from '../assets/images/team/tm/10.jpg';

const teamMembers = [
    {
        "name": "Abdul Wajed Quadri",
        "position": "Chairman",
        "img": tm01
      },
      {
        "name": "Siddiqui Abdul Qavi",
        "position": "Vice Chairman",
        "img": tm02
      },
      {
        "name": "Shaikh Naser",
        "position": "Secretary",
        "img": tm03
      },
      {
        "name": "Syed Saleemoddin Hashmi",
        "position": "Treasurer",
        "img": tm04
      },
      {
        "name": "Quadri Abdul Jawwad",
        "position": "Director",
        "img": tm05
      },
      {
        "name": "Shaikh Pashu",
        "position": "Director",
        "img": tm06
      },
      {
        "name": "CA Anwar Hussain",
        "position": "Director",
        "img": tm07
      },
      {
        "name": "Mohd. Qadeer Khan",
        "position": "Director",
        "img": tm08
      },
      {
        "name": "Quraishi Fahimunnisa",
        "position": "Director",
        "img": tm09
      },
      {
        "name": "Siddiqui Shakera Begum",
        "position": "Director",
        "img": tm10
      }
];

export default function TeamSection() {
  return (
    <>
      {/* start team section */}
      <div className="rts-team-area rts-section-gap bg-team" style={{height:"unset"}}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="rts-title-area team text-center">
                <p className="pre-title">Board of Directors</p>
                <h2 className="title">Board of Directors</h2>
              </div>
            </div>
          </div>
          <div className="row g-5 mt--0">
            {teamMembers.map((member, index) => (
              <div className="col-lg-3 col-md-6 col-sm-12" key={index}>
                <div className="team-single-one-start">
                  <div className="team-image-area">
                    <a href="./">
                      <img src={member.img} alt={member.name}  style={{ height: "300px", objectFit: "cover" }} />
                      {/* <div className="team-social">
                        <div className="main">
                          <i className="fal fa-plus" />
                        </div>
                        <div className="team-social-one">
                          <i className="fab fa-youtube" />
                          <i className="fab fa-twitter" />
                          <i className="fab fa-instagram" />
                        </div>
                      </div> */}
                    </a>
                  </div>
                  <div className="single-details">
                    <a href="./">
                      <h5 className="title">{member.name}</h5>
                    </a>
                    <p>{member.position}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* end team section */}
    </>
  );
}
