import logo from '../assets/images/logo/logo-1.svg';
import img1 from "../assets/images/alkhairnews1.jpg";

export default function NewsSection() {

    return(<>
    <div className="rts-blog-list-area rts-section-gap">
  <div className="container">
    <div className="row g-5">
      {/* rts blog post area */}
      <div className="col-xl-8 col-md-12 col-sm-12 col-12">
        {/* single post */}
        <div className="blog-single-post-listing">
          <div className="">
            <img src={img1} alt="Business-Blog" />
          </div>
          <div className="blog-listing-content">
            <div className="user-info">
              {/* single info */}
              <div className="single">
                <i className="far fa-user-circle" />
                <span>by Alkhair Cooperative Credit Society Limited </span>
              </div>
              {/* single infoe end */}
              {/* single info */}
              <div className="single">
                <i className="far fa-clock" />
                <span>10 Oct, 2024</span>
              </div>
              {/* single infoe end */}
           
            </div>
            <span className="blog-title" >
              <h3 className="title animated fadeIn">
              सूचना/सावधानी
              </h3>
            </span>
            <p className="disc">
            आम जनता को सूचित किया जाता है कि अलखैर कोआपरेटिव क्रेडिट सोसाइटी लिमिटेड मल्टी-स्टेट कोआपरेटिव क्रेडिट सोसाइटी एक्ट के तहत पंजीकृत है और मिनिस्ट्री ऑफ कोआपरेटिव के अंतर्गत कार्य करती है। यह मेम्बर-आधारित सोसाइटी है, जो केवल अपने मेम्बर्स के साथ ही लेन-देन कर सकती है।

कृपया ध्यान दें कि हमारी सोसाइटी ऑनलाइन व्यापार नहीं करती है, और न ही किसी प्रकार का ऑनलाइन लोन प्रदान करती है। हमारी ओर से कभी भी लोन के लिए ऑनलाइन दस्तावेज़ या धनराशि जमा करने की मांग नहीं की जाती। ऋण प्राप्त करने के लिए मेम्बर होना आवश्यक है, और इसके लिए आवेदन केवल सोसाइटी की शाखा में, सोसाइटी के लोन फॉर्म पर ही किया जा सकता है।

यदि आपको किसी प्रकार का ऐसा फ़ोन आता है तो समझें कि आपसे धोखाधड़ी का प्रयास किया जा रहा है। कृपया सावधान रहें।
            </p>
            
          </div>
        </div>
        
      </div>
      {/* rts-blog post end area */}
      {/*rts blog wized area */}
      <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60">
        
       
        
        
       
        {/* single wized start */}
        <div className="rts-single-wized contact">
          <div className="wized-header">
            <a href="#">
              <img src={logo} alt="Business_logo" style={{height: "100px"}} />
            </a>
          </div>
          <div className="wized-body">
            <h5 className="title">Need Help? We Are Here To Help You</h5>
            <a className="rts-btn btn-primary" href="contactus.html">
              Contact Us
            </a>
          </div>
        </div>
        {/* single wized End */}
      </div>
      {/* rts- blog wized end area */}
    </div>
  </div>
</div>
    </>)
}