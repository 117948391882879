
import './assets/css/plugins/swiper.min.css';
import './assets/css/plugins/fontawesome-5.css';
import './assets/css/plugins/animate.min.css';
import './assets/css/plugins/unicons.css';
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/style.css';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './component/Header';
import Footer from './component/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Bylaws from './pages/Bylaws';
import Board from './pages/Board';
import News from './pages/News';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import Resolutions from './pages/Resolutions';

function NotFound() {
  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <p>The page you're looking for doesn't exist.</p>
    </div>
  );
}


function App() {
  useEffect(() => {
    document.body.classList.add('loaded');
    
  
  }, []);
  return (
    <>
    <Header/>
    <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={ <About/> } />
        <Route path="/bylaws" element={ <Bylaws/> } />
        <Route path="/board" element={ <Board/> } />
        <Route path="/news" element={ <News/> } />
        <Route path="/gallery" element={ <Gallery/> } />
        <Route path="/contact" element={ <Contact/> } />
        <Route path="/resolutions" element={ <Resolutions/> } />
        <Route path="*" element={<NotFound />} /> {/* 404 route */}
      </Routes>
    <Footer/>
    </>
  );
}

export default App;
